<template>
    <div class="">
        <div class="">
            <custom-alert v-if="displayAlert==true" :message="alertMessage"
                          v-on:showedAlert="resetAlert"></custom-alert>
            <div class="row profile-body ">
                <div class="col-md-6">
                    <div class="card mt-3">
                        <div class="card-header">
                            <div class="header-title">
                                <h3>President</h3>
                            </div>
                            <div class="modal-btn">
                                <b-button v-b-modal.member-lookup-modal style="width: 100px;margin-top:0;" @click="setContactType('2')">
                                    <img src="/dist/img/memberregistration/edit.png" alt="edit_icon"> Change
                                </b-button>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row" v-if="clubInfo.president_info!=null">
                                <div class="col-md-6 ">
                                    <div class="form-group">
                                        <label class="form-label">Member#</label>
                                        <span>{{ clubInfo.president_info.membership_id }}</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Email</label>
                                        <span>{{ clubInfo.president_info.email }}</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">First Name</label>
                                        <span>{{ clubInfo.president_info.first_name }}</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Last Name</label>
                                        <span>{{ clubInfo.president_info.last_name }}</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">City</label>
                                        <span>{{ clubInfo.president_info.city }}</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">State</label>
                                        <span>{{ clubInfo.president_info.state_code }}</span>
                                    </div>
                                </div>
                              <div  class="col-md-6">
                                <div class="form-group">
                                  <label class="form-label">Membership Status <span v-if="clubInfo.president_info.member.status_text" v-b-tooltip.hover :title="clubInfo.president_info.member.membership_status_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width:20px;" /></span></label>
                                  <span class="text-success" v-if="clubInfo.president_info.member.status == 1"><img class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubInfo.president_info.member.status_text }} (Expires {{ clubInfo.president_info.member.valid_thru | formatDob }})</span>
                                  <span class="text-warning"  v-else-if="clubInfo.president_info.member.status >=7"> <img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{ clubInfo.president_info.member.status_text }}</span>
                                  <span class="text-danger"  v-else> <img style="width:18px;" class="mr-1" src="/dist/img/profile/deactivated.png"> {{ clubInfo.president_info.member.status_text }}</span>
                                </div>
                              </div>
                              <div class="col-md-6" v-if="clubInfo.president_info.member.member_type_id != 2">
                                <div class="form-group">
                                  <label class="form-label">Waiver Status</label>
                                  <span class="text-success" v-if="clubInfo.president_info.member.waiver_status == 1"><img class="mr-1" src="/dist/img/profile/ico-status-current.png"> Signed</span>
                                  <span class="text-warning"  v-else> <img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> Not signed</span>
                                </div>
                              </div>
                              <template v-if="subdomain == 'waterski'">
                                <div  class="col-md-6">
                                  <div class="form-group" v-if="clubInfo.president_info.member.safe_sport_screening_compliance == true">
                                    <label class="form-label" >Safe Sport Training Status</label>
                                    <span class="text-success" v-if="clubInfo.president_info.member.safe_sport != null && clubInfo.president_info.member.safe_sport.safe_sport_status_text == 'Complete'"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubInfo.president_info.member.safe_sport.safe_sport_status_text}} (Expires {{ clubInfo.president_info.member.safe_sport.expiration }})</span>
                                    <span class="text-warning" v-else-if="clubInfo.president_info.member.safe_sport != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{clubInfo.president_info.member.safe_sport.safe_sport_status_text || "N/A"}}<template v-if="clubInfo.president_info.member.safe_sport.safe_sport_status == 13 && clubInfo.president_info.member.safe_sport.expiration"><br>(On {{ clubInfo.president_info.member.safe_sport.expiration }})</template></span>
                                    <span class="text-warning" v-else>Not Started</span>
                                  </div>
                                  <div class="form-group" v-else>
                                    <label class="form-label">Safe Sport Training Status</label>
                                    <span class="">N/A</span>
                                  </div>
                                </div>
                                <div  class="col-md-6">
                                    <div class="form-group" v-if="clubInfo.president_info.member.safe_sport_screening_compliance == true">
                                    <label class="form-label">Background Screen Status <template v-if="getSiteProps('tooltips.backgroundScreening')"><div class="d-inline" id="bgscreen-tooltip-president-0" v-b-tooltip.hover="{ customClass: 'bgscreen-tooltip' }"><img v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width: 20px" /></div><b-tooltip custom-class="bgscreenTooltip" target="bgscreen-tooltip-president-0" class="white"><p class="mb-0" v-html="getSiteProps('tooltips.backgroundScreening')"></p></b-tooltip></template></label>
                                    <span class="text-success" v-if="clubInfo.president_info.member.background_screening != null && clubInfo.president_info.member.background_screening.status == 2"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubInfo.president_info.member.background_screening.background_screening_status_text || "N/A"}} (Expires {{ clubInfo.president_info.member.background_screening.expiration | formatDob }})</span>
                                    <span class="text-warning" v-else-if="clubInfo.president_info.member.background_screening != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{clubInfo.president_info.member.background_screening.background_screening_status_text || "N/A"}}<template v-if="clubInfo.president_info.member.background_screening.status == 6 && clubInfo.president_info.member.background_screening.expiration"><br>(On {{ clubInfo.president_info.member.background_screening.expiration | formatDob }})</template></span>
                                    <span class="text-warning" v-else>Not Started</span>
                                  </div>
                                  <div class="form-group" v-else>
                                    <label class="form-label">Background Screen Status <template v-if="getSiteProps('tooltips.backgroundScreening')"><div class="d-inline" id="bgscreen-tooltip-president" v-b-tooltip.hover="{ customClass: 'bgscreen-tooltip' }"><img v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width: 20px" /></div><b-tooltip custom-class="bgscreenTooltip" target="bgscreen-tooltip-president" class="white"><p class="mb-0" v-html="getSiteProps('tooltips.backgroundScreening')"></p></b-tooltip></template></label>
                                    <span class="">N/A</span>
                                  </div>
                                </div>
                                <div  class="col-md-6">
                                  <div class="form-group" v-if="clubInfo.president_info.member.safe_sport_screening_compliance == true">
                                    <label class="form-label">MVR Status </label>
                                    <span class="text-success" v-if="clubInfo.president_info.member.background_mvr_review != null && clubInfo.president_info.member.background_mvr_review.status == 2"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubInfo.president_info.member.background_mvr_review.background_screening_status_text || "N/A"}} (Expires {{ clubInfo.president_info.member.background_mvr_review.expiration | formatDob }})</span>
                                    <span class="text-warning" v-else-if="clubInfo.president_info.member.background_mvr_review != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{clubInfo.president_info.member.background_mvr_review.background_screening_status_text || "N/A"}}<template v-if="clubInfo.president_info.member.background_mvr_review.status == 6 && clubInfo.president_info.member.background_mvr_review.expiration"><br>(On {{ clubInfo.president_info.member.background_mvr_review.expiration | formatDob }})</template></span>
                                    <span class="text-warning" v-else>Not Started</span>
                                  </div>
                                  <div class="form-group" v-else>
                                    <label class="form-label">MVR Status <template v-if="getSiteProps('tooltips.backgroundScreening')"><div class="d-inline" id="bgscreen-tooltip-president" v-b-tooltip.hover="{ customClass: 'bgscreen-tooltip' }"><img v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width: 20px" /></div><b-tooltip custom-class="bgscreenTooltip" target="bgscreen-tooltip-president" class="white"><p class="mb-0" v-html="getSiteProps('tooltips.backgroundScreening')"></p></b-tooltip></template></label>
                                    <span class="">N/A</span>
                                  </div>
                                </div>
                              </template>
                            </div>
                            <div class="row" v-if="clubInfo.president_info==null">
                                There are no records to show
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card mt-3">
                        <div class="card-header">
                            <div class="header-title">
                                <h3>Vice President</h3>
                            </div>
                            <div class="modal-btn">
                                <b-button v-b-modal.member-lookup-modal style="width: 100px;margin-top:0;" @click="setContactType('3')">
                                     <img src="/dist/img/memberregistration/edit.png" alt="edit_icon"> Change
                                </b-button>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row" v-if="clubInfo.vp_info!=null">
                                <div class="col-md-6 ">
                                    <div class="form-group">
                                        <label class="form-label">Member#</label>
                                        <span>{{ clubInfo.vp_info.membership_id }}</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Email</label>
                                        <span>{{ clubInfo.vp_info.email }}</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">First Name</label>
                                        <span>{{ clubInfo.vp_info.first_name }}</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Last Name</label>
                                        <span>{{ clubInfo.vp_info.last_name }}</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">City</label>
                                        <span>{{ clubInfo.vp_info.city }}</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">State</label>
                                        <span>{{ clubInfo.vp_info.state_code }}</span>
                                    </div>
                                </div>
                              <div  class="col-md-6">
                                <div class="form-group">
                                  <label class="form-label">Membership Status <span v-if="clubInfo.vp_info.member.status_text" v-b-tooltip.hover :title="clubInfo.vp_info.member.membership_status_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width:20px;" /></span></label>
                                  <span class="text-success" v-if="clubInfo.vp_info.member.status == 1"><img class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubInfo.vp_info.member.status_text }} (Expires {{ clubInfo.vp_info.member.valid_thru | formatDob }})</span>
                                  <span class="text-warning"  v-else-if="clubInfo.vp_info.member.status >=7"> <img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{ clubInfo.vp_info.member.status_text }}</span>
                                  <span class="text-danger"  v-else> <img style="width:18px;" class="mr-1" src="/dist/img/profile/deactivated.png"> {{ clubInfo.vp_info.member.status_text }}</span>
                                </div>
                              </div>
                              <div class="col-md-6" v-if="clubInfo.vp_info.member.member_type_id != 2">
                                <div class="form-group">
                                  <label class="form-label">Waiver Status</label>
                                  <span class="text-success" v-if="clubInfo.vp_info.member.waiver_status == 1"><img class="mr-1" src="/dist/img/profile/ico-status-current.png"> Signed</span>
                                  <span class="text-warning"  v-else> <img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> Not signed</span>
                                </div>
                              </div>
                              <template v-if="subdomain == 'waterski'">
                                <div  class="col-md-6">
                                  <div class="form-group" v-if="clubInfo.vp_info.member.safe_sport_screening_compliance == true">
                                    <label class="form-label" >Safe Sport Training Status</label>
                                    <span class="text-success" v-if="clubInfo.vp_info.member.safe_sport != null && clubInfo.vp_info.member.safe_sport.safe_sport_status_text == 'Complete'"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubInfo.vp_info.member.safe_sport.safe_sport_status_text}} (Expires {{ clubInfo.vp_info.member.safe_sport.expiration }})</span>
                                    <span class="text-warning" v-else-if="clubInfo.vp_info.member.safe_sport != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{clubInfo.vp_info.member.safe_sport.safe_sport_status_text || "N/A"}}<template v-if="clubInfo.vp_info.member.safe_sport.safe_sport_status == 13 && clubInfo.vp_info.member.safe_sport.expiration"><br>(On {{ clubInfo.vp_info.member.safe_sport.expiration }})</template></span>
                                    <span class="text-warning" v-else>Not Started</span>
                                  </div>
                                  <div class="form-group" v-else>
                                    <label class="form-label">Safe Sport Training Status</label>
                                    <span class="">N/A</span>
                                  </div>
                                </div>
                                <div  class="col-md-6">
                                  <div class="form-group" v-if="clubInfo.vp_info.member.safe_sport_screening_compliance == true">
                                    <label class="form-label">Background Screen Status <template v-if="getSiteProps('tooltips.backgroundScreening')"><div class="d-inline" id="bgscreen-tooltip-president-0" v-b-tooltip.hover="{ customClass: 'bgscreen-tooltip' }"><img v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width: 20px" /></div><b-tooltip custom-class="bgscreenTooltip" target="bgscreen-tooltip-president-0" class="white"><p class="mb-0" v-html="getSiteProps('tooltips.backgroundScreening')"></p></b-tooltip></template></label>
                                    <span class="text-success" v-if="clubInfo.vp_info.member.background_screening != null && clubInfo.vp_info.member.background_screening.status == 2"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubInfo.vp_info.member.background_screening.background_screening_status_text || "N/A"}} (Expires {{ clubInfo.vp_info.member.background_screening.expiration | formatDob }})</span>
                                    <span class="text-warning" v-else-if="clubInfo.vp_info.member.background_screening != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{clubInfo.vp_info.member.background_screening.background_screening_status_text || "N/A"}}<template v-if="clubInfo.vp_info.member.background_screening.status == 6 && clubInfo.vp_info.member.background_screening.expiration"><br>(On {{ clubInfo.vp_info.member.background_screening.expiration | formatDob }})</template></span>
                                    <span class="text-warning" v-else>Not Started</span>
                                  </div>
                                  <div class="form-group" v-else>
                                    <label class="form-label">Background Screen Status <template v-if="getSiteProps('tooltips.backgroundScreening')"><div class="d-inline" id="bgscreen-tooltip-president" v-b-tooltip.hover="{ customClass: 'bgscreen-tooltip' }"><img v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width: 20px" /></div><b-tooltip custom-class="bgscreenTooltip" target="bgscreen-tooltip-president" class="white"><p class="mb-0" v-html="getSiteProps('tooltips.backgroundScreening')"></p></b-tooltip></template></label>
                                    <span class="">N/A</span>
                                  </div>
                                </div>
                                <div  class="col-md-6">
                                  <div class="form-group" v-if="clubInfo.vp_info.member.safe_sport_screening_compliance == true">
                                    <label class="form-label">MVR Status </label>
                                    <span class="text-success" v-if="clubInfo.vp_info.member.background_mvr_review != null && clubInfo.vp_info.member.background_mvr_review.status == 2"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubInfo.vp_info.member.background_mvr_review.background_screening_status_text || "N/A"}} (Expires {{ clubInfo.vp_info.member.background_mvr_review.expiration | formatDob }})</span>
                                    <span class="text-warning" v-else-if="clubInfo.vp_info.member.background_mvr_review != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{clubInfo.vp_info.member.background_mvr_review.background_screening_status_text || "N/A"}}<template v-if="clubInfo.vp_info.member.background_mvr_review.status == 6 && clubInfo.vp_info.member.background_mvr_review.expiration"><br>(On {{ clubInfo.vp_info.member.background_mvr_review.expiration | formatDob }})</template></span>
                                    <span class="text-warning" v-else>Not Started</span>
                                  </div>
                                  <div class="form-group" v-else>
                                    <label class="form-label">MVR Status <template v-if="getSiteProps('tooltips.backgroundScreening')"><div class="d-inline" id="bgscreen-tooltip-president" v-b-tooltip.hover="{ customClass: 'bgscreen-tooltip' }"><img v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width: 20px" /></div><b-tooltip custom-class="bgscreenTooltip" target="bgscreen-tooltip-president" class="white"><p class="mb-0" v-html="getSiteProps('tooltips.backgroundScreening')"></p></b-tooltip></template></label>
                                    <span class="">N/A</span>
                                  </div>
                                </div>
                              </template>
                            </div>
                            <div class="row" v-if="clubInfo.vp_info==null">
                                There are no records to show
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row profile-body">
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-header">
                            <div class="header-title">
                                <h3>Secretary</h3>
                            </div>
                            <div class="modal-btn">
                                <b-button v-b-modal.member-lookup-modal style="width: 100px; margin-top:0;" @click="setContactType('4')">
                                    <img src="/dist/img/memberregistration/edit.png" alt="edit_icon"> Change
                                </b-button>
                            </div>
                        </div>
                        <div class="card-body">                                
                            <div class="row" v-if="clubInfo.secretary_info!=null">
                                <div class="col-md-6 ">
                                    <div class="form-group">
                                        <label class="form-label">Member#</label>
                                        <span>{{ clubInfo.secretary_info.membership_id }}</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Email</label>
                                        <span>{{ clubInfo.secretary_info.email }}</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">First Name</label>
                                        <span>{{ clubInfo.secretary_info.first_name }}</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Last Name</label>
                                        <span>{{ clubInfo.secretary_info.last_name }}</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">City</label>
                                        <span>{{ clubInfo.secretary_info.city }}</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">State</label>
                                        <span>{{ clubInfo.secretary_info.state_code }}</span>
                                    </div>
                                </div>
                              <div  class="col-md-6">
                                <div class="form-group">
                                  <label class="form-label">Membership Status <span v-if="clubInfo.secretary_info.member.status_text" v-b-tooltip.hover :title="clubInfo.secretary_info.member.membership_status_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width:20px;" /></span></label>
                                  <span class="text-success" v-if="clubInfo.secretary_info.member.status == 1"><img class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubInfo.secretary_info.member.status_text }} (Expires {{ clubInfo.secretary_info.member.valid_thru | formatDob }})</span>
                                  <span class="text-warning"  v-else-if="clubInfo.secretary_info.member.status >=7"> <img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{ clubInfo.secretary_info.member.status_text }}</span>
                                  <span class="text-danger"  v-else> <img style="width:18px;" class="mr-1" src="/dist/img/profile/deactivated.png"> {{ clubInfo.secretary_info.member.status_text }}</span>
                                </div>
                              </div>
                              <div class="col-md-6" v-if="clubInfo.secretary_info.member.member_type_id != 2">
                                <div class="form-group">
                                  <label class="form-label">Waiver Status</label>
                                  <span class="text-success" v-if="clubInfo.secretary_info.member.waiver_status == 1"><img class="mr-1" src="/dist/img/profile/ico-status-current.png"> Signed</span>
                                  <span class="text-warning"  v-else> <img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> Not signed</span>
                                </div>
                              </div>
                              <template v-if="subdomain == 'waterski'">
                                <div  class="col-md-6">
                                  <div class="form-group" v-if="clubInfo.secretary_info.member.safe_sport_screening_compliance == true">
                                    <label class="form-label" >Safe Sport Training Status</label>
                                    <span class="text-success" v-if="clubInfo.secretary_info.member.safe_sport != null && clubInfo.secretary_info.member.safe_sport.safe_sport_status_text == 'Complete'"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubInfo.secretary_info.member.safe_sport.safe_sport_status_text}} (Expires {{ clubInfo.secretary_info.member.safe_sport.expiration }})</span>
                                    <span class="text-warning" v-else-if="clubInfo.secretary_info.member.safe_sport != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{clubInfo.secretary_info.member.safe_sport.safe_sport_status_text || "N/A"}}<template v-if="clubInfo.secretary_info.member.safe_sport.safe_sport_status == 13 && clubInfo.secretary_info.member.safe_sport.expiration"><br>(On {{ clubInfo.secretary_info.member.safe_sport.expiration }})</template></span>
                                    <span class="text-warning" v-else>Not Started</span>
                                  </div>
                                  <div class="form-group" v-else>
                                    <label class="form-label">Safe Sport Training Status</label>
                                    <span class="">N/A</span>
                                  </div>
                                </div>
                                <div  class="col-md-6">
                                  <div class="form-group" v-if="clubInfo.secretary_info.member.safe_sport_screening_compliance == true">
                                    <label class="form-label">Background Screen Status <template v-if="getSiteProps('tooltips.backgroundScreening')"><div class="d-inline" id="bgscreen-tooltip-president-0" v-b-tooltip.hover="{ customClass: 'bgscreen-tooltip' }"><img v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width: 20px" /></div><b-tooltip custom-class="bgscreenTooltip" target="bgscreen-tooltip-president-0" class="white"><p class="mb-0" v-html="getSiteProps('tooltips.backgroundScreening')"></p></b-tooltip></template></label>
                                    <span class="text-success" v-if="clubInfo.secretary_info.member.background_screening != null && clubInfo.secretary_info.member.background_screening.status == 2"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubInfo.secretary_info.member.background_screening.background_screening_status_text || "N/A"}} (Expires {{ clubInfo.secretary_info.member.background_screening.expiration | formatDob }})</span>
                                    <span class="text-warning" v-else-if="clubInfo.secretary_info.member.background_screening != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{clubInfo.secretary_info.member.background_screening.background_screening_status_text || "N/A"}}<template v-if="clubInfo.secretary_info.member.background_screening.status == 6 && clubInfo.secretary_info.member.background_screening.expiration"><br>(On {{ clubInfo.secretary_info.member.background_screening.expiration | formatDob }})</template></span>
                                    <span class="text-warning" v-else>Not Started</span>
                                  </div>
                                  <div class="form-group" v-else>
                                    <label class="form-label">Background Screen Status <template v-if="getSiteProps('tooltips.backgroundScreening')"><div class="d-inline" id="bgscreen-tooltip-president" v-b-tooltip.hover="{ customClass: 'bgscreen-tooltip' }"><img v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width: 20px" /></div><b-tooltip custom-class="bgscreenTooltip" target="bgscreen-tooltip-president" class="white"><p class="mb-0" v-html="getSiteProps('tooltips.backgroundScreening')"></p></b-tooltip></template></label>
                                    <span class="">N/A</span>
                                  </div>
                                </div>
                                <div  class="col-md-6">
                                  <div class="form-group" v-if="clubInfo.secretary_info.member.safe_sport_screening_compliance == true">
                                    <label class="form-label">MVR Status </label>
                                    <span class="text-success" v-if="clubInfo.secretary_info.member.background_mvr_review != null && clubInfo.secretary_info.member.background_mvr_review.status == 2"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubInfo.secretary_info.member.background_mvr_review.background_screening_status_text || "N/A"}} (Expires {{ clubInfo.secretary_info.member.background_mvr_review.expiration | formatDob }})</span>
                                    <span class="text-warning" v-else-if="clubInfo.secretary_info.member.background_mvr_review != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{clubInfo.secretary_info.member.background_mvr_review.background_screening_status_text || "N/A"}}<template v-if="clubInfo.secretary_info.member.background_mvr_review.status == 6 && clubInfo.secretary_info.member.background_mvr_review.expiration"><br>(On {{ clubInfo.secretary_info.member.background_mvr_review.expiration | formatDob }})</template></span>
                                    <span class="text-warning" v-else>Not Started</span>
                                  </div>
                                  <div class="form-group" v-else>
                                    <label class="form-label">MVR Status <template v-if="getSiteProps('tooltips.backgroundScreening')"><div class="d-inline" id="bgscreen-tooltip-president" v-b-tooltip.hover="{ customClass: 'bgscreen-tooltip' }"><img v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width: 20px" /></div><b-tooltip custom-class="bgscreenTooltip" target="bgscreen-tooltip-president" class="white"><p class="mb-0" v-html="getSiteProps('tooltips.backgroundScreening')"></p></b-tooltip></template></label>
                                    <span class="">N/A</span>
                                  </div>
                                </div>
                              </template>
                            </div>
                            <div class="row" v-if="clubInfo.secretary_info==null">
                                There are no records to show
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card">
                        <div class="card-header">
                            <div class="header-title">
                                <h3>Treasurer</h3>
                            </div>
                            <div class="modal-btn">
                                <b-button v-b-modal.member-lookup-modal style="width: 100px;margin-top:0;" @click="setContactType('5')">
                                    <img src="/dist/img/memberregistration/edit.png" alt="edit_icon"> Change
                                </b-button>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="row" v-if="clubInfo.treasurer_info!=null">
                                <div class="col-md-6 ">
                                    <div class="form-group">
                                        <label class="form-label">Member#</label>
                                        <span>{{ clubInfo.treasurer_info.membership_id }}</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Email</label>
                                        <span>{{ clubInfo.treasurer_info.email }}</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>First Name</label>
                                        <span>{{ clubInfo.treasurer_info.first_name }}</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">Last Name</label>
                                        <span>{{ clubInfo.treasurer_info.last_name }}</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">City</label>
                                        <span>{{ clubInfo.treasurer_info.city }}</span>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label class="form-label">State</label>
                                        <span>{{ clubInfo.treasurer_info.state_code }}</span>
                                    </div>
                                </div>
                              <div  class="col-md-6">
                                <div class="form-group">
                                  <label class="form-label">Membership Status <span v-if="clubInfo.treasurer_info.member.status_text" v-b-tooltip.hover :title="clubInfo.treasurer_info.member.membership_status_info"> <img  v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width:20px;" /></span></label>
                                  <span class="text-success" v-if="clubInfo.treasurer_info.member.status == 1"><img class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubInfo.treasurer_info.member.status_text }} (Expires {{ clubInfo.treasurer_info.member.valid_thru | formatDob }})</span>
                                  <span class="text-warning"  v-else-if="clubInfo.treasurer_info.member.status >=7"> <img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{ clubInfo.treasurer_info.member.status_text }}</span>
                                  <span class="text-danger"  v-else> <img style="width:18px;" class="mr-1" src="/dist/img/profile/deactivated.png"> {{ clubInfo.treasurer_info.member.status_text }}</span>
                                </div>
                              </div>
                              <div class="col-md-6" v-if="clubInfo.treasurer_info.member.member_type_id != 2">
                                <div class="form-group">
                                  <label class="form-label">Waiver Status</label>
                                  <span class="text-success" v-if="clubInfo.treasurer_info.member.waiver_status == 1"><img class="mr-1" src="/dist/img/profile/ico-status-current.png"> Signed</span>
                                  <span class="text-warning"  v-else> <img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> Not signed</span>
                                </div>
                              </div>
                              <template v-if="subdomain == 'waterski'">
                                <div  class="col-md-6">
                                  <div class="form-group" v-if="clubInfo.treasurer_info.member.safe_sport_screening_compliance == true">
                                    <label class="form-label" >Safe Sport Training Status</label>
                                    <span class="text-success" v-if="clubInfo.treasurer_info.member.safe_sport != null && clubInfo.treasurer_info.member.safe_sport.safe_sport_status_text == 'Complete'"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubInfo.treasurer_info.member.safe_sport.safe_sport_status_text}} (Expires {{ clubInfo.treasurer_info.member.safe_sport.expiration }})</span>
                                    <span class="text-warning" v-else-if="clubInfo.treasurer_info.member.safe_sport != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{clubInfo.treasurer_info.member.safe_sport.safe_sport_status_text || "N/A"}}<template v-if="clubInfo.treasurer_info.member.safe_sport.safe_sport_status == 13 && clubInfo.treasurer_info.member.safe_sport.expiration"><br>(On {{ clubInfo.treasurer_info.member.safe_sport.expiration }})</template></span>
                                    <span class="text-warning" v-else>Not Started</span>
                                  </div>
                                  <div class="form-group" v-else>
                                    <label class="form-label">Safe Sport Training Status </label>
                                    <span class="">N/A</span>
                                  </div>
                                </div>
                                <div  class="col-md-6">
                                  <div class="form-group" v-if="clubInfo.treasurer_info.member.safe_sport_screening_compliance == true">
                                    <label class="form-label">Background Screen Status <template v-if="getSiteProps('tooltips.backgroundScreening')"><div class="d-inline" id="bgscreen-tooltip-president-0" v-b-tooltip.hover="{ customClass: 'bgscreen-tooltip' }"><img v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width: 20px" /></div><b-tooltip custom-class="bgscreenTooltip" target="bgscreen-tooltip-president-0" class="white"><p class="mb-0" v-html="getSiteProps('tooltips.backgroundScreening')"></p></b-tooltip></template></label>
                                    <span class="text-success" v-if="clubInfo.treasurer_info.member.background_screening != null && clubInfo.treasurer_info.member.background_screening.status == 2"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubInfo.treasurer_info.member.background_screening.background_screening_status_text || "N/A"}} (Expires {{ clubInfo.treasurer_info.member.background_screening.expiration | formatDob }})</span>
                                    <span class="text-warning" v-else-if="clubInfo.treasurer_info.member.background_screening != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{clubInfo.treasurer_info.member.background_screening.background_screening_status_text || "N/A"}}<template v-if="clubInfo.treasurer_info.member.background_screening.status == 6 && clubInfo.treasurer_info.member.background_screening.expiration"><br>(On {{ clubInfo.treasurer_info.member.background_screening.expiration | formatDob }})</template></span>
                                    <span class="text-warning" v-else>Not Started</span>
                                  </div>
                                  <div class="form-group" v-else>
                                    <label class="form-label">Background Screen Status <template v-if="getSiteProps('tooltips.backgroundScreening')"><div class="d-inline" id="bgscreen-tooltip-president" v-b-tooltip.hover="{ customClass: 'bgscreen-tooltip' }"><img v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width: 20px" /></div><b-tooltip custom-class="bgscreenTooltip" target="bgscreen-tooltip-president" class="white"><p class="mb-0" v-html="getSiteProps('tooltips.backgroundScreening')"></p></b-tooltip></template></label>
                                    <span class="">N/A</span>
                                  </div>
                                </div>
                                <div  class="col-md-6">
                                  <div class="form-group" v-if="clubInfo.treasurer_info.member.safe_sport_screening_compliance == true">
                                    <label class="form-label">MVR Status </label>
                                    <span class="text-success" v-if="clubInfo.treasurer_info.member.background_mvr_review != null && clubInfo.treasurer_info.member.background_mvr_review.status == 2"><img  class="mr-1" src="/dist/img/profile/ico-status-current.png"> {{ clubInfo.treasurer_info.member.background_mvr_review.background_screening_status_text || "N/A"}} (Expires {{ clubInfo.treasurer_info.member.background_mvr_review.expiration | formatDob }})</span>
                                    <span class="text-warning" v-else-if="clubInfo.treasurer_info.member.background_mvr_review != null"><img class="mr-1" src="/dist/img/profile/ico-status-pending.png"> {{clubInfo.treasurer_info.member.background_mvr_review.background_screening_status_text || "N/A"}}<template v-if="clubInfo.treasurer_info.member.background_mvr_review.status == 6 && clubInfo.treasurer_info.member.background_mvr_review.expiration"><br>(On {{ clubInfo.treasurer_info.member.background_mvr_review.expiration | formatDob }})</template></span>
                                    <span class="text-warning" v-else>Not Started</span>
                                  </div>
                                  <div class="form-group" v-else>
                                    <label class="form-label">MVR Status <template v-if="getSiteProps('tooltips.backgroundScreening')"><div class="d-inline" id="bgscreen-tooltip-president" v-b-tooltip.hover="{ customClass: 'bgscreen-tooltip' }"><img v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width: 20px" /></div><b-tooltip custom-class="bgscreenTooltip" target="bgscreen-tooltip-president" class="white"><p class="mb-0" v-html="getSiteProps('tooltips.backgroundScreening')"></p></b-tooltip></template></label>
                                    <span class="">N/A</span>
                                  </div>
                                </div>
                              </template>
                            </div>
                            <div class="row" v-if="clubInfo.treasurer_info==null">
                                There are no records to show
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row profile-body">
                <div class="col-md-12">
                    <div class="card  border-none">
                        <div class="card-header bg-white">
                            <div class="header-title">
                                <h3>Safe Sport Coordinator(s)</h3>
                            </div>
                            <div class="modal-btn">
                                <b-button v-if="clubInfo.safe_sport_coordinator.length==0" v-b-modal.member-lookup-modal style="width: 100px" @click="setContactType('8')">
                                     <img src="/dist/img/memberregistration/edit.png" alt="edit_icon"> Add
                                </b-button>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive">
                            <div class="pl-2 pr-2" v-if="prop_req_ssc.desc">
                                <span class="text-justify d-block mb-2"><i>Requirements:</i></span>
                                <template v-if="prop_req_ssc.desc.info">
                                    <ul class="jdlinfo" v-for="(item,k) in prop_req_ssc.desc.info" :key="'desc_' + k">
                                        <li v-html="item"></li>
                                    </ul>
                                </template>
                            </div>
                            <b-table
                                id="my-table"
                                :items="clubInfo.safe_sport_coordinator"
                                :fields="tblFields"
                                class="mt-3"
                                hover
                                show-empty
                            >
                            <template
                                :fields="items"
                            >
                                <div class="text-left py-0 align-middle">
                                    <div class="btn-group btn-group-sm">
                                        <button class="btn btn-info"><i class="fas fa-eye"></i></button>
                                        <button class="btn btn-primary ml-1"><i class="fas fa-pencil-alt"></i>
                                        </button>
                                        <button class="btn btn-danger ml-1"><i class="fas fa-trash"></i></button>
                                    </div>
                                </div>
                            </template>
                            <template #head(bgStatus)="data">
                            {{ data.label }} 
                                <template v-if="getSiteProps('tooltips.backgroundScreening')">
                                    <div class="d-inline" id="bgscreen-tooltip" v-b-tooltip.hover="{ customClass: 'bgscreen-tooltip' }">
                                    <img v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width: 20px" />
                                    </div>
                                    <b-tooltip custom-class="bgscreenTooltip" target="bgscreen-tooltip" class="white">                                        
                                    <p class="mb-0" v-html="getSiteProps('tooltips.backgroundScreening')"></p>
                                    </b-tooltip>
                                </template>
                            </template>
                                <template #empty="scope">
                                    <h6>{{ scope.emptyText }}</h6>
                                </template>
                                <template #cell(actions)="row">
                                    <div class="text-left py-0 align-middle">
                                        <div class="btn-group btn-group-sm">
                                            <button class="btn btn-danger ml-1" @click="deleteRow(row.item.id)"><i
                                                class="fas fa-trash"></i></button>
                                        </div>
                                    </div>
                                </template>
                                <template #cell(memStatus)="row">
                                  <span v-if="row.item.member.status == 1">{{ row.item.member.status_text }} (Expires {{ row.item.member.valid_thru | formatDob }})</span>
                                  <span v-else-if="row.item.member.status >=7">{{ row.item.member.status_text }}</span>
                                  <span v-else>{{ row.item.member.status_text }}</span>
                                </template>
                              <template #cell(waiverStatus)="row">
                                <span v-if="row.item.member.waiver_status == 1">Signed</span>
                                <span v-else>Not signed</span>
                              </template>
                              <template #cell(ssStatus)="row">
                                <span v-if="row.item.member.safe_sport_screening_compliance == true && row.item.member.safe_sport.safe_sport_status_text == 'Complete'">{{ row.item.member.safe_sport.safe_sport_status_text}} (Expires {{ row.item.member.safe_sport.expiration }})</span>
                                <span v-else-if="row.item.member.safe_sport_screening_compliance == true">{{row.item.member.safe_sport.safe_sport_status_text || "N/A"}}<template v-if="row.item.member.safe_sport.safe_sport_status == 13 && row.item.member.safe_sport.expiration"><br>(On {{ row.item.member.safe_sport.expiration }})</template></span>
                          <span v-else>Not Started</span>
                              </template>
                              <template #cell(bgStatus)="row">
                                <span v-if="row.item.member.background_screening">
                                  <span v-if="row.item.member.background_screening.status == 2">{{ row.item.member.background_screening.background_screening_status_text || "N/A"}} (Expires {{ row.item.member.background_screening.expiration | formatDob}})</span>
                                  <span v-else>{{row.item.member.background_screening.background_screening_status_text || "N/A"}}<template v-if="row.item.member.background_screening.status == 6 && row.item.member.background_screening.expiration"><br>(On {{ row.item.member.background_screening.expiration | formatDob }})</template></span>
                                </span>
                                <span v-else>Not Started</span>
                              </template>
                              <template #cell(mvrStatus)="row">
                          <span v-if="row.item.member.background_mvr_review">
                            <span class="" v-if="row.item.member.background_mvr_review.status == 2">{{ row.item.member.background_mvr_review.background_screening_status_text || "N/A"}} (Expires {{ row.item.member.background_mvr_review.expiration | formatDob}})</span>
                            <span class="" v-else>{{row.item.member.background_mvr_review.background_screening_status_text || "N/A"}}<template v-if="row.item.member.background_mvr_review.status == 6 && row.item.member.background_mvr_review.expiration"><br>(On {{ row.item.member.background_mvr_review.expiration | formatDob }})</template></span>
                          </span>
                                <span v-else>Not Started</span>
                              </template>
                            </b-table>
                        </div>
                        <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                </div>
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="header-title">
                                <h3>Junior Development Leader(s)</h3>
                            </div>
                            <div class="modal-btn">
                                <b-button v-b-modal.member-lookup-modal style="width: 100px" @click="setContactType('1')">
                                   <img src="/dist/img/memberregistration/edit.png" alt="edit_icon"> Add
                                </b-button>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive">
                            <div class="pl-2 pr-2" v-if="prop_req_junior.show_ski">
                                <span class="text-justify d-block mb-2"><i>Requirements:</i></span>
                                <template v-if="prop_req_junior.show_ski.info">
                                    <ul class="jdlinfo" v-for="(item,k) in prop_req_junior.show_ski.info" :key="'desc_' + k">
                                        <li v-html="item"></li>
                                    </ul>
                                </template>
                            </div>
                            <b-table
                                id="my-table"
                                :items="clubInfo.junior_development"
                        :fields="tblFields"
                        class="mt-3"
                        hover
                        show-empty
                    >
                      <template
                          :fields="items"
                      >
                        <div class="text-left py-0 align-middle">
                          <div class="btn-group btn-group-sm">
                            <button class="btn btn-info"><i class="fas fa-eye"></i></button>
                            <button class="btn btn-primary ml-1"><i class="fas fa-pencil-alt"></i>
                            </button>
                            <button class="btn btn-danger ml-1"><i class="fas fa-trash"></i></button>
                          </div>
                        </div>
                      </template>
                      <template #head(bgStatus)="data">
                            {{ data.label }} 
                                <template v-if="getSiteProps('tooltips.backgroundScreening')">
                                    <div class="d-inline" id="bgscreen-tooltip-js" v-b-tooltip.hover="{ customClass: 'bgscreen-tooltip-js' }">
                                    <img v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width: 20px" />
                                    </div>
                                    <b-tooltip custom-class="bgscreenTooltip" target="bgscreen-tooltip-js" class="white">                                        
                                    <p class="mb-0" v-html="getSiteProps('tooltips.backgroundScreening')"></p>
                                    </b-tooltip>
                                </template>
                            </template>
                            
                      <template #empty="scope">
                        <h6>{{ scope.emptyText }}</h6>
                      </template>
                      <template #cell(actions)="row">
                        <div class="text-left py-0 align-middle">
                          <div class="btn-group btn-group-sm">
                            <button class="btn btn-danger ml-1" @click="deleteRow(row.item.id)"><i
                                class="fas fa-trash"></i></button>
                          </div>
                        </div>
                      </template>
                      <template #cell(memStatus)="row">
                        <span v-if="row.item.member.status == 1">{{ row.item.member.status_text }} (Expires {{ row.item.member.valid_thru | formatDob }})</span>
                        <span v-else-if="row.item.member.status >=7">{{ row.item.member.status_text }}</span>
                        <span v-else>{{ row.item.member.status_text }}</span>
                      </template>
                      <template #cell(waiverStatus)="row">
                        <span v-if="row.item.member.waiver_status == 1">Signed</span>
                        <span v-else>Not signed</span>
                      </template>
                      <template #cell(ssStatus)="row">
                        <span v-if="row.item.member.safe_sport_screening_compliance == true && row.item.member.safe_sport.safe_sport_status_text == 'Complete'">{{ row.item.member.safe_sport.safe_sport_status_text}} (Expires {{ row.item.member.safe_sport.expiration }})</span>
                        <span v-else-if="row.item.member.safe_sport_screening_compliance == true">{{row.item.member.safe_sport.safe_sport_status_text || "N/A"}}<template v-if="row.item.member.safe_sport.safe_sport_status == 13 && row.item.member.safe_sport.expiration"><br>(On {{ row.item.member.safe_sport.expiration }})</template></span>
                          <span v-else>Not Started</span>
                      </template>
                      <template #cell(bgStatus)="row">
                                <span v-if="row.item.member.background_screening">
                                  <span v-if="row.item.member.background_screening.status == 2">{{ row.item.member.background_screening.background_screening_status_text || "N/A"}} (Expires {{ row.item.member.background_screening.expiration | formatDob}})</span>
                                  <span v-else>{{row.item.member.background_screening.background_screening_status_text || "N/A"}}<template v-if="row.item.member.background_screening.status == 6 && row.item.member.background_screening.expiration"><br>(On {{ row.item.member.background_screening.expiration | formatDob }})</template></span>
                                </span>
                        <span v-else>Not Started</span>
                      </template>
                              <template #cell(mvrStatus)="row">
                          <span v-if="row.item.member.background_mvr_review">
                            <span class="" v-if="row.item.member.background_mvr_review.status == 2">{{ row.item.member.background_mvr_review.background_screening_status_text || "N/A"}} (Expires {{ row.item.member.background_mvr_review.expiration | formatDob}})</span>
                            <span class="" v-else>{{row.item.member.background_mvr_review.background_screening_status_text || "N/A"}}<template v-if="row.item.member.background_mvr_review.status == 6 && row.item.member.background_mvr_review.expiration"><br>(On {{ row.item.member.background_screening.expiration | formatDob }})</template></span>
                          </span>
                                <span v-else>Not Started</span>
                              </template>
                    </b-table>
                  </div>
                  <!-- /.card-body -->
                </div>
                <!-- /.card -->
              </div>

               
            </div>

            <div class="row profile-body pb-3">
                 <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="header-title">
                                <h3>Show Director(s)</h3>
                            </div>
                            <div class="modal-btn">
                                <b-button v-b-modal.member-lookup-modal style="width: 100px" @click="setContactType('6')">
                                  <img src="/dist/img/memberregistration/edit.png" alt="edit_icon"> Add
                                </b-button>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive">
                            <div class="pl-2 pr-2" v-if="prop_req_director.show_ski">
                                <span class="text-justify d-block mb-2"><i>Requirements:</i></span>
                                <template v-if="prop_req_director.show_ski.info">
                                    <ul class="jdlinfo" v-for="(item,k) in prop_req_director.show_ski.info" :key="'desc_' + k">
                                        <li v-html="item"></li>
                                    </ul>
                                </template>
                            </div>
                            <b-table
                                id="my-table"
                                :items="clubInfo.ski_directors"
                                :fields="tblFields"
                                class="mt-3"
                                hover
                                show-empty
                            >
                                <template
                                    :fields="items"
                                >
                                    <div class="text-left py-0 align-middle">
                                        <div class="btn-group btn-group-sm">
                                            <button class="btn btn-info"><i class="fas fa-eye"></i></button>
                                            <button class="btn btn-primary ml-1"><i class="fas fa-pencil-alt"></i>
                                            </button>
                                            <button class="btn btn-danger ml-1"><i class="fas fa-trash"></i></button>
                                        </div>
                                    </div>
                                </template>
                           <template #head(bgStatus)="data">
                            {{ data.label }} 
                                <template v-if="getSiteProps('tooltips.backgroundScreening')">
                                    <div class="d-inline" id="bgscreen-tooltip-sd" v-b-tooltip.hover="{ customClass: 'bgscreen-tooltip-sd' }">
                                    <img v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width: 20px" />
                                    </div>
                                    <b-tooltip custom-class="bgscreenTooltip" target="bgscreen-tooltip-sd" class="white">                                        
                                    <p class="mb-0" v-html="getSiteProps('tooltips.backgroundScreening')"></p>
                                    </b-tooltip>
                                </template>
                            </template>
                                <template #empty="scope">
                                    <h6>{{ scope.emptyText }}</h6>
                                </template>
                                <template #cell(actions)="row">
                                    <div class="text-left py-0 align-middle">
                                        <div class="btn-group btn-group-sm">
                                            <button class="btn btn-danger ml-1" @click="deleteRow(row.item.id)"><i
                                                class="fas fa-trash"></i></button>
                                        </div>
                                    </div>
                                </template>
                              <template #cell(memStatus)="row">
                                <span v-if="row.item.member.status == 1">{{ row.item.member.status_text }} (Expires {{ row.item.member.valid_thru | formatDob }})</span>
                                <span v-else-if="row.item.member.status >=7">{{ row.item.member.status_text }}</span>
                                <span v-else>{{ row.item.member.status_text }}</span>
                              </template>
                              <template #cell(waiverStatus)="row">
                                <span v-if="row.item.member.waiver_status == 1">Signed</span>
                                <span v-else>Not signed</span>
                              </template>
                              <template #cell(ssStatus)="row">
                                <span v-if="row.item.member.safe_sport_screening_compliance == true && row.item.member.safe_sport.safe_sport_status_text == 'Complete'">{{ row.item.member.safe_sport.safe_sport_status_text}} (Expires {{ row.item.member.safe_sport.expiration }})</span>
                                <span v-else-if="row.item.member.safe_sport_screening_compliance == true">{{row.item.member.safe_sport.safe_sport_status_text || "N/A"}}<template v-if="row.item.member.safe_sport.safe_sport_status == 13 && row.item.member.safe_sport.expiration"><br>(On {{ row.item.member.safe_sport.expiration }})</template></span>
                          <span v-else>Not Started</span>
                              </template>
                              <template #cell(bgStatus)="row">
                                <span v-if="row.item.member.background_screening">
                                  <span v-if="row.item.member.background_screening.status == 2">{{ row.item.member.background_screening.background_screening_status_text || "N/A"}} (Expires {{ row.item.member.background_screening.expiration | formatDob}})</span>
                                  <span v-else>{{row.item.member.background_screening.background_screening_status_text || "N/A"}}<template v-if="row.item.member.background_screening.status == 6 && row.item.member.background_screening.expiration"><br>(On {{ row.item.member.background_screening.expiration | formatDob }})</template></span>
                                </span>
                                <span v-else>Not Started</span>
                              </template>
                              <template #cell(mvrStatus)="row">
                          <span v-if="row.item.member.background_mvr_review">
                            <span class="" v-if="row.item.member.background_mvr_review.status == 2">{{ row.item.member.background_mvr_review.background_screening_status_text || "N/A"}} (Expires {{ row.item.member.background_mvr_review.expiration | formatDob}})</span>
                            <span class="" v-else>{{row.item.member.background_mvr_review.background_screening_status_text || "N/A"}}<template v-if="row.item.member.background_mvr_review.status == 6 && row.item.member.background_mvr_review.expiration"><br>(On {{ row.item.member.background_mvr_review.expiration | formatDob }})</template></span>
                          </span>
                                <span v-else>Not Started</span>
                              </template>
                            </b-table>
                        </div>
                        <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                </div>
                <div class="col-md-12">
                    <div class="card  border-none">
                        <div class="card-header bg-white">
                            <div class="header-title">
                                <h3>Assistant Show Director(s)</h3>
                            </div>
                            <div class="modal-btn">
                                <b-button v-b-modal.member-lookup-modal style="width: 100px" @click="setContactType('7')">
                                     <img src="/dist/img/memberregistration/edit.png" alt="edit_icon"> Add
                                </b-button>
                            </div>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body table-responsive">
                            <div class="pl-2 pr-2" v-if="prop_req_asst_dir.show_ski">
                                <span class="text-justify d-block mb-2"><i>Requirements:</i></span>
                                <template v-if="prop_req_asst_dir.show_ski.info">
                                    <ul class="jdlinfo" v-for="(item,k) in prop_req_asst_dir.show_ski.info" :key="'desc_' + k">
                                        <li v-html="item"></li>
                                    </ul>
                                </template>
                            </div>
                  <b-table
                      id="my-table"
                                :items="clubInfo.assistant_skidirectors"
                      :fields="tblFields"
                      class="mt-3"
                      hover
                      show-empty
                  >
                    <template
                        :fields="items"
                    >
                      <div class="text-left py-0 align-middle">
                        <div class="btn-group btn-group-sm">
                          <button class="btn btn-info"><i class="fas fa-eye"></i></button>
                          <button class="btn btn-primary ml-1"><i class="fas fa-pencil-alt"></i>
                          </button>
                          <button class="btn btn-danger ml-1"><i class="fas fa-trash"></i></button>
                        </div>
                      </div>
                    </template>
                         <template #head(bgStatus)="data">
                            {{ data.label }} 
                                <template v-if="getSiteProps('tooltips.backgroundScreening')">
                                    <div class="d-inline" id="bgscreen-tooltip-asd" v-b-tooltip.hover="{ customClass: 'bgscreen-tooltip-asd' }">
                                    <img v-bind:src="'/dist/img/memberregistration/tooltip-icon-small.png'" alt="tooltipicon" style="width: 20px" />
                                    </div>
                                    <b-tooltip custom-class="bgscreenTooltip" target="bgscreen-tooltip-asd" class="white">                                        
                                    <p class="mb-0" v-html="getSiteProps('tooltips.backgroundScreening')"></p>
                                    </b-tooltip>
                                </template>
                            </template>
                    <template #empty="scope">
                      <h6>{{ scope.emptyText }}</h6>
                    </template>

                    <template #cell(actions)="row">
                      <div class="text-left py-0 align-middle">
                        <div class="btn-group btn-group-sm">
                          <button class="btn btn-danger ml-1" @click="deleteRow(row.item.id)"><i
                              class="fas fa-trash"></i></button>
                        </div>
                      </div>
                    </template>
                    <template #cell(memStatus)="row">
                      <span v-if="row.item.member.status == 1">{{ row.item.member.status_text }} (Expires {{ row.item.member.valid_thru | formatDob }})</span>
                      <span v-else-if="row.item.member.status >=7">{{ row.item.member.status_text }}</span>
                      <span v-else>{{ row.item.member.status_text }}</span>
                    </template>
                    <template #cell(waiverStatus)="row">
                      <span v-if="row.item.member.waiver_status == 1">Signed</span>
                      <span v-else>Not signed</span>
                    </template>
                    <template #cell(ssStatus)="row">
                      <span v-if="row.item.member.safe_sport_screening_compliance == true && row.item.member.safe_sport.safe_sport_status_text == 'Complete'">{{ row.item.member.safe_sport.safe_sport_status_text}} (Expires {{ row.item.member.safe_sport.expiration }})</span>
                      <span v-else-if="row.item.member.safe_sport_screening_compliance == true">{{row.item.member.safe_sport.safe_sport_status_text || "N/A"}}<template v-if="row.item.member.safe_sport.safe_sport_status == 13 && row.item.member.safe_sport.expiration"><br>(On {{ row.item.member.safe_sport.expiration }})</template></span>
                          <span v-else>Not Started</span>
                    </template>
                    <template #cell(bgStatus)="row">
                      <span v-if="row.item.member.background_screening">
                        <span v-if="row.item.member.background_screening.status == 2">{{ row.item.member.background_screening.background_screening_status_text || "N/A"}} (Expires {{ row.item.member.background_screening.expiration | formatDob}})</span>
                        <span v-else>{{row.item.member.background_screening.background_screening_status_text || "N/A"}}<template v-if="row.item.member.background_screening.status == 6 && row.item.member.background_screening.expiration"><br>(On {{ row.item.member.background_screening.expiration | formatDob }})</template></span>
                      </span>
                      <span v-else>Not Started</span>
                    </template>
                    <template #cell(mvrStatus)="row">
                          <span v-if="row.item.member.background_mvr_review">
                            <span class="" v-if="row.item.member.background_mvr_review.status == 2">{{ row.item.member.background_mvr_review.background_screening_status_text || "N/A"}} (Expires {{ row.item.member.background_mvr_review.expiration | formatDob}})</span>
                            <span class="" v-else>{{row.item.member.background_mvr_review.background_screening_status_text || "N/A"}}<template v-if="row.item.member.background_mvr_review.status == 6 && row.item.member.background_mvr_review.expiration"><br>(On {{ row.item.member.background_mvr_review.expiration | formatDob }})</template></span>
                          </span>
                      <span v-else>Not Started</span>
                    </template>
                  </b-table>
                </div>
                <!-- /.card-body -->
              </div>
              <!-- /.card -->
            </div>
                
          </div>
        </div>


        <div class="personalinfo-modal">
            <b-modal id="modal-center1" size="lg" ok-title="Update" centered title="President">
                <div class="form-body">
                    <form>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="clubname" class="form-label">Member</label>
                                    <input type="text" id="clubname" value="" placeholder="Castro Valley Ski Club"
                                           class="form-control">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="clubtype" class="form-label">First Name</label>
                                    <input type="text" id="clubtype" value="" placeholder="3-Event - AWS"
                                           class="form-control">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="clubwebsite" class="form-label">Last Name</label>
                                    <input type="text" id="clubwebsite" value="" placeholder="clubnew.com"
                                           class="form-control">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="mailingaddress" class="form-label">City</label>
                                    <input type="text" id="mailingaddress" value="" placeholder="69840 Ezra Oval"
                                           class="form-control">
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="mailingaddress" class="form-label">State</label>
                                    <select class="form-control">
                                        <option>Aruba</option>
                                        <option>Aruba</option>
                                        <option>Aruba</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </b-modal>
        </div>

        <member-lookup v-on:member-lookup-data="ChangeClubMember" :editContactType="editContactType"></member-lookup>

    </div>
</template>

<script>
// import clubnavigation from "./components/Navigation";
// import clubsidebar from "./components/Sidebar";
// import clubdboardfooter from "./components/Footer";
import MemberLookup from "./components/MemberLookup";
import CustomAlert from "../../CustomAlert";
import axios from 'axios';

export default {
    name: "clubofficials",
    components: {CustomAlert, MemberLookup},
    data() {
        return {
            contactType: "",
            editContactType:"",
            clubInfo: [],
            tblFields: [
                { key: 'membership_id', sortable: true, label: 'Member #' },
                {  key: 'first_name', sortable: true },
                { key: 'last_name', sortable: true },
                { key: 'email', sortable: true },
                { key: 'city', sortable: true, label: 'City' },
                { key: 'state_code', sortable: true, label: 'State' },
                { key: 'memStatus', sortable: false, label: 'Membership Status' },
                { key: 'waiverStatus', sortable: false, label: 'Waiver Status' },
                { key: 'ssStatus', sortable: false, label: 'Safe Sport Training Status' },
                { key: 'bgStatus', sortable: false, label: 'Background Screen Status' },
                { key: 'mvrStatus', sortable: false, label: 'MVR Status' },
                { key: 'actions', sortable: false },
            ],
            infoModal: {
                id: 'info-modal',
                title: '',
                content: ''
            },
            prop_req_junior: "",
            prop_req_director: "",
            prop_req_asst_dir: "",
            prop_req_ssc: "",
        }
    },
    methods: {
        loadPropReqData(){
            const r1 = setInterval(()=>{
                this.prop_req_junior = this.getSiteProps('memberRegistration.club.fields.club_junior');
                if(this.prop_req_junior) clearInterval(r1);
            }, 100);

            const r2 = setInterval(()=>{
                this.prop_req_director = this.getSiteProps('memberRegistration.club.fields.club_director');
                if(this.prop_req_director) clearInterval(r2);
            }, 100);

            const r3 = setInterval(()=>{
                this.prop_req_asst_dir = this.getSiteProps('memberRegistration.club.fields.club_director');
                if(this.prop_req_asst_dir) clearInterval(r3);
            }, 100);

            const r4 = setInterval(()=>{
                this.prop_req_ssc = this.getSiteProps('memberRegistration.club.fields.safe_sport_coordinator');
                if(this.prop_req_ssc) clearInterval(r4);
            }, 100);
        },
        loadClubInfo() {
            axios.get(this.basePath+"api/club/info",{ headers:this.clubHeaders })
                .then((response)=>{
                    const resp  = response.data.data;
                    this.clubInfo = resp;
                }).catch((error) =>{
                console.log(error);
            });
        },
        ChangeClubMember(data){
            data.contact_type=this.contactType;
            this.displayAlert = false;
            axios.post(this.basePath + 'api/club/contacts', data, {headers: this.clubHeaders})
                .then(function (response) {
                    this.alertMessage = "Club member changed successfully";
                    this.displayAlert = true;
                    this.loadClubInfo();
                }.bind(this))
                .catch(function (error) {
                    console.log(error);
                });
        },
        resetAlert(){
            this.displayAlert = false;
        },
        setContactType(type){
            this.contactType=type;
          this.editContactType = type;
        },
        deleteRow(id){
            this.displayAlert = false;
            this.$bvModal.msgBoxConfirm('Please confirm that you want to delete the Club Officials.', {
                title: 'Please Confirm',
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                okTitle: 'YES',
                cancelTitle: 'NO',
                footerClass: 'p-2',
                hideHeaderClose: false,
                centered: true
            })
                .then(value => {
                    if(String(value)=="true") {
                        axios.delete(this.basePath+'api/club/del_contacts/'+id,{headers: this.clubHeaders})
                            .then(function (response) {
                                this.alertMessage = "Club member deleted successfully";
                                this.displayAlert = true;
                                this.loadClubInfo();
                            }.bind(this))
                            .catch(function (error) {
                                console.log(error);
                            });
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        }
    },
    mounted() {
        this.loadClubInfo();
        this.loadPropReqData();
    }
}
</script>
